/**
 * Created by Mikhail Menshenin on 19.09.2024
 */

SimpleMainScene.prototype.onSceneLoaded = cleverapps.extendFunc(SimpleMainScene.prototype.onSceneLoaded, function () {
    this._super();

    if (aisensia.levelMastery.isAvailable) {
        var levelMastery = this.levelMastery = new LevelMasteryView({ type: "scene", timer: true });
        levelMastery.setPositionRound(cleverapps.styles.LevelMasteryView);
        this.addChild(levelMastery);
        levelMastery.setLocalZOrder(15);
        levelMastery.registerControl();
        levelMastery.updateSize();
    }
});
