/**
 * Created by olga on 02.08.2024
 */

Mission.TYPE_LETTER = 3;

Missions.FEATURES[Mission.TYPE_LETTER] = function () {
    Missions[Mission.TYPE_LETTER] = {
        available: {
            level: 1.8
        },
        view: TournamentWindow,
        duration: "1 day",
        startWindow: {
            name: "LetterTournamentGuideWindow",
            bundle: bundles.lettertournamentguidewindow
        },
        prize: RewardsConfig.Tournament.places,
        teaser: true,
        name: "LetterTournament",
        collectIcon: bundles.tournament_icon.frames.mission_letter_png,
        semaphore: Missions.SEMAPHORE_SECONDARY,
        competition: {
            step: [2, 6],
            amount: [20, 30]
        },
        sideBarJson: bundles.sidebar.jsons.mission_letter_icon_json,
        finishWindow: TournamentWindow,
        bundle: "letter_tournament",
        STAR_TYPES_AMOUNT: 2
    };
};