/**
 * Created by olga on 02.08.2024
 */

SimpleLocation.prototype.amountLevelStars = function () {
    var game = Game.currentGame;
    var score = game.score.points;
    if (score > 200) {
        return 3;
    }
    if (score > 150) {
        return 2;
    }
    if (score > 100) {
        return 1;
    }
    return 0;
};

SimpleLocation.prototype.getCurrentPercent = function () {
    var total = this.getRequiredProgress();
    var percent = cleverapps.meta.getMainObject().getProgress() / total * 100;
    return (percent < 100) ? percent : 100;
};

SimpleLocation.prototype.onOpen = function (f) {
    var scene = cleverapps.scenes.getRunningScene();
    cleverapps.focusManager.compound(f, [
        function (f) {
            var bundles = this.getBundles();
            if (bundles) {
                cleverapps.bundleLoader.loadBundles(bundles);
            }
            connector.social.markAchievement({});
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SIMPLE_METHA_LEVELUP);
            f();
        }.bind(this),
        function (f) {
            cleverapps.meta.trigger("moveNext", f);
        },
        function (f) {
            scene.moveNextBg(f);
        }
    ]);
};

Placements.ENTRIES["simpleMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        return cleverapps.meta.getMainObject().checkCompleted();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "simpleMoveNextLocation",
            control: ["progress_view"],
            actions: [
                function (f) {
                    cleverapps.meta.getMainObject().onComplete(f);
                },
                function (f) {
                    cleverapps.meta.moveNextLocation();
                    cleverapps.meta.getMainObject().onOpen(f);
                },
                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel) {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};

SimpleLocation.prototype.checkCompleted = function () {
    if (this.progress >= this.getRequiredProgress() && cleverapps.meta.currentLocationId < cleverapps.meta.getLastLocationId() - 1) {
        return true;
    }
};

Forces.SIMPLE = {
    id: 32,
    text: "Simple.Force",
    finger: false
};